import { Avatar, Typography, Grid } from "@mui/material"

export const AvatarCard = ({ name, description, image }) => {
    return (
        <Grid container justifyContent={'center'} p={5}>
            <Grid item container direction='column' alignItems={'center'}>
                <Grid item>
                    <Avatar
                        alt={name}
                        src={image}
                        sx={{ width: 150, height: 150 }}
                    />
                </Grid>
                <Grid item p={2}>
                    <Typography variant="h3">
                        {name}
                    </Typography>
                    <Typography pt={1}>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}