import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { CssBaseline, ThemeProvider } from "@mui/material";
import { darkTheme } from "./themes/theme";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode className='content'>
    <ThemeProvider theme={darkTheme}>
    <CssBaseline enableColorScheme />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

