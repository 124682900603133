import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const SocialButton = ({ icon, text, url="#" }) => {

    return (
        <Box display={'flex'} justifyContent={'center'} m={3}>
            <Button
                variant="contained"
                size="large"
                sx={{
                    padding: 2,
                    minWidth: "300px"
                }}
                href={url}
            >
                {icon}
                <Typography pl={"20px"}>
                    {text}
                </Typography>
            </Button>
        </Box>
    );
}