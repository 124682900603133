import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { AvatarCard } from "./components/Avatar/AvatarCard";
import { Divider } from "@mui/material";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { SocialButton } from "./components/SocialButton";

import avatarImage from "./img/avatarImage.jpeg";

function App() {
  return (
    <Box display={"flex"} justifyContent={'center'}>
      <Box maxWidth={'600px'} width='600px'>
        <Paper sx={{pb:"20px"}}>
          <Box mt={{xs:0, sm:2, md:'150px', lg:'150px'}}></Box>
          <AvatarCard 
          image={avatarImage}
          name={"John Kraczek"} 
          description={"React, Wordpress, & Automation Developer"} />
          <Divider></Divider>
          <SocialButton text={"Save Contact"} icon={<ContactPhoneIcon/>} url={'/JohnKraczek.vcf'} />
          <SocialButton text={"Book A Meeting"} icon={<CalendarMonthIcon/>} url={"https://api.leadconnectorhq.com/widget/booking/0uwzPwm1zcMAux86NjwE"}/>
          <SocialButton text={"Website"} icon={<SmartButtonIcon/>} url={"https://yourdigitaltoolbox.com/"}/>
          <SocialButton text={"YouTube"} icon={<YouTubeIcon/>} url={"https://youtube.com/johnkraczek"}/>
          <SocialButton text={"Instagram"} icon={<InstagramIcon/>} url={"https://instagram.com/johnkraczek"}/>
          <SocialButton text={"LinkedIn"} icon={<LinkedInIcon/>} url={"https://linkedin.com/in/johnkraczek"}/>
          <SocialButton text={"Twitter"} icon={<TwitterIcon/>} url={"https://twitter.com/johnkraczek"}/>
        </Paper>
      </Box>
    </Box>
  );
}

export default App;
